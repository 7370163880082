import {FC} from "react";

interface GridProps extends Grid {
  children: any[]
}

const Grid: FC<GridProps> = ({cols, children}) => {
  const gridClasses = {
    '2': 'md:grid-cols-2',
    '3': 'md:grid-cols-2 lg:grid-cols-3',
    '4': 'md:grid-cols-2 lg:grid-cols-4',
  }[cols]

  return (
    <div className={`grid gap-[32px] ${gridClasses}`}>
      {children}
    </div>
  )
}

export default Grid;