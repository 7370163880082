import {FC, useContext} from "react";

import {Context} from "@/components/Section";
import ListItem, {ListItemProps} from "@/components/molecules/ListItem";

const ListItemMapper: FC<Omit<ListItemProps, 'background'>> = ({children, ...rest}) => {
    const background = useContext(Context).background as BackgroundValues

    return <ListItem {...rest} background={background} >
        {children}
    </ListItem>
}

export default ListItemMapper