import React, {useContext} from "react";
import {Context} from "@/components/Section";

interface IconProps extends Icon {
}

const Icon: React.FC<IconProps> = ({value, variant}) => {
  const background = useContext(Context).background

  const getIconClasses = (background: string, variant: ButtonVariantValues): string => {
    if (variant === 'CTA' || variant === 'CTA Green') {
      return 'fill-neutral-50';
    }

    if (variant === 'Primary') {
      switch (background) {
        case "white":
        case "light":
          return "fill-neutral-50"
        case "dark":
          return "fill-neutral-900"
      }
    }

    if (variant === 'Secondary') {
      switch (background) {
        case "white":
        case "light":
          return "fill-navy-900"
        case "dark":
          return "fill-neutral-50"
      }
    }

    if (variant === 'Text') {
      switch (background) {
        case "white":
        case "light":
          return "fill-teal-700 hover:fill-teal-900"
        case "dark":
          return "fill-teal-300 hover:fill-teal-100"
      }
    }

    return ''
  }

  return ((value, background, variant) => {
    switch (value) {
      case "add":
        return <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                    className={`w-[16px] h-[16px] ${getIconClasses(background, variant)}`}>
          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
        </svg>
      case "arrow":
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    className={`w-[16px] h-[16px] ${getIconClasses(background, variant)}`}>
          <path d="M15 5L13.59 6.41L18.17 11H2V13H18.17L13.58 17.59L15 19L22 12L15 5Z"/>
        </svg>
      case "check":
        return <svg viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg"
                    className={`w-[12px] h-[12px] ${getIconClasses(background, variant)}`}>
          <path
            d="M5.80001 10.9L1.60001 6.70005L0.200012 8.10005L5.80001 13.7L17.8 1.70005L16.4 0.300049L5.80001 10.9Z"/>
        </svg>
      case "chevron left":
        return <svg viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg"
                    className={`w-[12px] h-[12px] ${getIconClasses(background, variant)}`}>
          <path
            d="M11.835 1.86961L10.055 0.0996094L0.165001 9.99961L10.065 19.8996L11.835 18.1296L3.705 9.99961L11.835 1.86961Z"/>
        </svg>
      case "chevron right":
        return <svg viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg"
                    className={`w-[12px] h-[12px] ${getIconClasses(background, variant)}`}>
          <path d="M0.115005 18.23L1.88501 20L11.885 10L1.88501 0L0.115005 1.77L8.34501 10L0.115005 18.23Z"/>
        </svg>
      case "close":
        return <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                    className={`w-[12px] h-[12px] ${getIconClasses(background, variant)}`}>
          <path
            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"/>
        </svg>
      case "none":
      default:
        return null
    }
  })(value, background, variant)
}

export default Icon;