import {useContext} from "react";
import {Context} from "@/components/Section";
import CardGrid, {CardGridProps} from "@/components/molecules/CardGrid";

interface CardGridMapperProps extends CardGridProps {

}

const CardGridMapper = ({...rest}: CardGridMapperProps) => {
  const background = useContext(Context).background as BackgroundValues

  return <CardGrid {...rest} background={background}/>
}

export default CardGridMapper