import {FC, ReactNode} from "react";
import CaseStudy from "@/components/atoms/CaseStudy";

export interface CaseStudiesProps {
  companies: { imageSrc: string, title: string, description: string, url: string }[]
  children: ReactNode
}

const CaseStudies: FC<CaseStudiesProps> = ({companies, children}) => {
  return (
      <div className="justify-center w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col justify-center self-stretch my-auto max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col max-md:max-w-full">
                {children}
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col gap-5 grow max-md:mt-10 max-md:max-w-full">
              {companies.map((company, index) => (
                <CaseStudy key={index} {...company} />
              ))}
            </div>
          </div>
        </div>
      </div>
  );
}

export default CaseStudies;