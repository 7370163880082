import {FC, } from "react";
import CaseStudies, {CaseStudiesProps} from "@/components/molecules/CaseStudies";


const CaseStudiesMapper: FC<CaseStudiesProps> = ({children, ...rest}) => {

    return <CaseStudies {...rest}>
        {children}
    </CaseStudies>
}

export default CaseStudiesMapper