import React from "react";
import Icon from "@/components/atoms/Icon";

interface LinkProps extends Link {
}

const Link: React.FC<LinkProps> = ({variant, label, iconPosition, icon, url, target}) => {
  const variantClasses = {
    'light ui': 'text-teal-700 hover:text-teal-900',
    'dark ui': 'text-teal-300 hover:text-teal-100',
  }[variant]

  return (
    <a href={url}
       className={`flex items-center gap-1 text-base font-medium tracking-normal leading-6 ${variantClasses}`}>
      {iconPosition === 'before' && <Icon value={icon} variant={'Text'} />}
      <span>{label}</span>
      {iconPosition === 'after' && <Icon value={icon} variant={'Text'} />}
    </a>)
}

export default Link;