import {FC} from "react";
import SquareCard from "@/components/atoms/SquareCard";

export interface CardGridProps extends CardGrid {
}

const CardGrid: FC<CardGridProps> = ({background, cards}) => {
  const backgroundMapped = background === 'white' ? 'light' : background

  return (
    <div className={'hidden lg:flex justify-center space-x-[24px]'}>
      <div className={'w-[225px] flex-col group/column'}>
        {cards
          .filter((_, index) => index % 3 === 0)
          .map((card, index) => <SquareCard
            key={index}
            backgroundColor={index % 2 === 0 ? "white" : 'light'}
            {...card}
          />)
        }
      </div>
      <div className={'w-[225px] flex-col group/column'}>
        {cards
          .filter((_, index) => (index-1) % 3 === 0)
          .map((card, index) => <SquareCard
            key={index}
            backgroundColor={index % 2 !== 0 ? "white" : 'light'}
            {...card}
          />)
        }
      </div>
      <div className={'w-[225px] flex-col group/column'}>
        {cards
          .filter((_, index) => (index-2) % 3 === 0)
          .map((card, index) => <SquareCard
            key={index}
            backgroundColor={index % 2 === 0 ? "white" : 'light'}
            {...card}
          />)
        }
      </div>
    </div>
  );
};

export default CardGrid;