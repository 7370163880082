import {useContext} from "react";
import {Context} from "@/components/Section";
import Card from "@/components/molecules/Card";

interface CardMapperProps extends Card {

}

const CardMapper = ({...rest}: CardMapperProps) => {
  const background = useContext(Context).background as BackgroundValues

  return <Card {...rest} background={background}/>
}

export default CardMapper