import React, {FC, ReactNode} from "react";

interface SectionProps extends Section {
  children: ReactNode
}

export const Context = React.createContext({background: 'dark'});

const Section: FC<SectionProps> = ({background, backgroundPattern, narrowMargins, children}) => {
  const lightPattern = `bg-[url('/bg/light-pattern.png')] bg-no-repeat bg-right-top`
  const darkPattern = `bg-[url('/bg/dark-pattern.png')] bg-no-repeat bg-right-top`

  const backgroundClasses = {
    'white': `bg-white ${backgroundPattern && lightPattern}`,
    'light': `bg-navy-50 ${backgroundPattern && lightPattern}`,
    'dark': `bg-navy-900 ${backgroundPattern && darkPattern}`
  }[background]

  return (
    <section className={`flex flex-col ${narrowMargins ? 'p-[24px]' : 'p-20'} max-md:px-5 w-full ${backgroundClasses}`}>
      <div className={'max-w-[70rem] w-full mx-auto text-center'}>
        <Context.Provider value={{background: background}}>
          {children}
        </Context.Provider>
      </div>
    </section>
  )
}

export default Section;