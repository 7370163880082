import React, {useContext} from "react";
import {Context} from "@/components/Section";

interface TextProps {
  text: string
}

const Text: React.FC<TextProps> = ({text}) => {
  const bg = useContext(Context).background
  const background = bg === 'white' ? 'light' : bg

  const textClasses = {
    'light': 'text-neutral-900',
    'dark': 'text-neutral-200',
  }[background]

  return <div className={`text-left ${textClasses}`} dangerouslySetInnerHTML={{'__html': text}}/>
}

export default Text;