import {FC} from "react";

export interface CardProps extends Card {
}

const Card: FC<CardProps> = ({imageSrc, altText, description, background}) => {
  const backgroundMapped = background === 'white' ? 'light' : background

  const cardClasses = {
    'light': 'bg-navy-50 text-neutral-700',
    'dark': 'bg-navy-800 text-neutral-200',
  }[backgroundMapped]

  return (
    <div
      className={`flex flex-col grow text-lg tracking-normal leading-7 rounded-lg shadow-md overflow-hidden w-full ${cardClasses}`}>
      <img loading="lazy" src={imageSrc} alt={altText} className="w-full aspect-[1.79] object-cover"/>
      <div className="flex flex-col justify-center px-6 py-8 w-full text-left">
        <p className="justify-center">{description}</p>
      </div>
    </div>
  );
};

export default Card;