import {FC, useContext} from "react";

import {Context} from "@/components/Section";
import Tile, {TileProps} from "@/components/molecules/Tile";

const TileMapper: FC<Omit<TileProps, 'background'>> = (props) => {
    const background = useContext(Context).background as BackgroundValues

    return <Tile {...props} background={background} />
}

export default TileMapper