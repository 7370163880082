import React from "react"

interface SquareCardProps {
  title: string
  description: string
  url: string
  backgroundColor: 'white' | 'light'
}

const SquareCard: React.FC<SquareCardProps> = ({title, description, url, backgroundColor}) => {
  const bgClasses = {
    'white': 'bg-white',
    'light': 'bg-navy-100',
  }[backgroundColor]

  const cardAnimation = `
    h-[200px] group-hover/column:h-[170px] group-hover/card:!h-[260px]
    p-[16px] hover:pt-[32px]
    transition-all
    duration-300
  `

  const descriptionAnimation = `
    h-[5px] group-hover/card:h-[120px]
    top-[190px] group-hover/card:top-[130px]
    transition-[height] transition-[top]
    duration-300
  `

  const iconAnimation = `
    top-[144px] group-hover/column:top-[100px] group-hover/card:!top-[90px]
    transition-[top]
    duration-300
  `

  return <div className={`group/card py-[12px]`}>
    <a href={url}
       className={`block w-[225px] border-[2px] border-neutral-300 shadow rounded-[16px] p-[16px] cursor-pointer text-left overflow-hidden relative ${bgClasses} ${cardAnimation}`}>
      <span className={'block text-neutral-900 text-[34px] font-[700] leading-[41px]'}>{title}</span>
      <span className={`block absolute left-[16px] right-[16px] overflow-hidden ${descriptionAnimation}`}>{description}</span>

      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none"
           className={`absolute right-[16px] ${iconAnimation}`}>
        <path
          d="M16.8096 10.507L7.84407 19.114L6.37093 17.6997L15.3365 9.09284L7.43454 9.09284V7.09314H18.8926V18.0929H16.8096L16.8096 10.507Z"
          fill="#CD2026"/>
      </svg>
    </a>
  </div>
}

export default SquareCard;