import {FC, useContext} from "react";

import {Context} from "@/components/Section";
import Carousel, {CarouselProps} from "@/components/molecules/Carousel";

const CarouselMapper: FC<Omit<CarouselProps, 'background'>> = (props) => {
    const background = useContext(Context).background as BackgroundValues

    return <Carousel {...props} background={background} />
}

export default CarouselMapper