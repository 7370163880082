import {FC, useContext} from "react";
import ExpandingListGroup, {ExpandingListGroupProps} from "@/components/molecules/ExpandingListGroup";
import {Context} from "@/components/Section";

const ExpandingListGroupMapping: FC<ExpandingListGroupProps> = ({items}) => {
    const background = useContext(Context).background as BackgroundValues

    return <ExpandingListGroup items={items} background={background}></ExpandingListGroup>
}

export default ExpandingListGroupMapping