import {FC, useState} from "react";
import ExpandingListItem, {ExpandingListItemProps} from "@/components/atoms/ExpandingListItem";

export interface ExpandingListGroupProps {
  items: ExpandingListItemProps[],
  background: BackgroundValues,
}

const ExpandingListGroup: FC<ExpandingListGroupProps> = ({items, background}) => {
  const backgroundMapped = background === 'white' ? 'light' : background

  const [selected, setSelected] = useState(0)

  return (
    <>
      {items && items.length && items.map((item, index) => <div
        key={index}
        className={'cursor-pointer'}
        onClick={() => setSelected(index)}
      >
        <ExpandingListItem
          imageSrc={item.imageSrc}
          title={item.title}
          description={item.description}
          background={backgroundMapped}
          isCollapsed={index !== selected}
        />
      </div>)}
    </>
  )
}

export default ExpandingListGroup;