import {FC} from "react";

export type CaseStudyProps = {
    imageSrc: string;
    title: string;
    description: string;
    url: string;
};


// TODO url property is unused
const CaseStudy: FC<CaseStudyProps> = ({ imageSrc, title, description, url }) => (
    <article className="flex flex-col justify-center bg-white rounded shadow-md max-md:max-w-full">
        <div className="flex flex-col px-[24px] py-[32px] max-md:px-5 max-md:max-w-full">
            <img loading="lazy" src={imageSrc} alt={title} className="w-[135px]"/>
            <div className={'text-left'} dangerouslySetInnerHTML={{'__html': description}} />
        </div>
    </article>
);

export default CaseStudy;