import React, {ReactNode} from "react"
import H1Headline from "@/components/atoms/H1Headline";
import H2Headline from "@/components/atoms/H2Headline";
import H3Headline from "@/components/atoms/H3Headline";
import H4Headline from "@/components/atoms/H4Headline";
import Eyebrow from "@/components/atoms/Eyebrow";

export interface SectionHeadingsProps extends SectionHeadings {
  children: ReactNode
  background: BackgroundValues
}

const SectionHeadings: React.FC<SectionHeadingsProps> = ({layout, eyebrow, heading, headingType, headingColor, subtitle,background,  children}) => {
  const backgroundMapped = background === 'white' ? 'light' : background

  // backward compatible
  if (!headingType) {
    headingType = 'H2 Headline'
  }
  if (!headingColor) {
    headingColor = 'default'
  }

  const eyebrowClasses = {
    'light': 'text-red-600',
    'dark': 'text-red-500',
  }[backgroundMapped]

  const headingColorClasses = ((background, color) => {
    if (color === 'default') {
      switch (background) {
        case "light":
          return 'text-neutral-900'
        case "dark":
          return 'text-neutral-50'
      }
    }
    if (color === 'green') {
      switch (background) {
        case "light":
          return 'text-teal-700'
        case "dark":
          return 'text-teal-300'
      }
    }
    if (color === 'red') {
      switch (background) {
        case "light":
          return 'text-red-600'
        case "dark":
          return 'text-red-500'
      }
    }

    return ''
  })(backgroundMapped, headingColor)

  const subtitleClasses = {
    'light': 'text-neutral-700',
    'dark': 'text-neutral-200',
  }[backgroundMapped]

  const layoutClasses = {
    'left': 'text-left',
    'center': 'text-center',
  }[layout];

  const childrenClasses = {
    'left': '',
    'center': 'items-center',
  }[layout];

  const convertNewlinesToBreaks = (text: string) => {
    return text
      .split('\n')
      .map((item, index) => `<p class="mb-[20px]">${item}</p>`)
      .join('')
  }

  const getHeading = (headingType: HeadingTypeValues) => {
    switch (headingType) {
      case "H1 Headline":
        return <H1Headline text={heading} background={background} className={`max-md:max-w-full ${headingColorClasses}`} />
      case "H2 Headline":
        return <H2Headline text={heading} className={`max-md:max-w-full ${headingColorClasses}`} />
      case "H3 Headline":
        return <H3Headline text={heading} className={`max-md:max-w-full ${headingColorClasses}`} />
      case "H4 Headline":
        return <H4Headline text={heading} className={`max-md:max-w-full ${headingColorClasses}`} />
    }
  }

  return (
    <section className={`flex flex-col w-[100%] ${layoutClasses}`}>
      {eyebrow && <Eyebrow text={eyebrow} className={`md:text-nowrap max-md:max-w-full mb-4 ${eyebrowClasses}`} />}
      {getHeading(headingType)}
      {subtitle && <p className={`mt-4 text-xl leading-7 ${subtitleClasses} max-md:max-w-full`} dangerouslySetInnerHTML={{'__html': convertNewlinesToBreaks(subtitle)}} />}
      <div className={`flex flex-col ${childrenClasses}`}>
        {children}
      </div>
    </section>
  )
}

export default SectionHeadings;