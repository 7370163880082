import {FC, useContext} from "react";

import {Context} from "@/components/Section";
import Hero, {HeroProps} from "@/components/molecules/Hero";

const HeroMapper: FC<Omit<HeroProps, 'background'>> = ({children, ...rest}) => {
    const background = useContext(Context).background as BackgroundValues

    return <Hero {...rest} background={background}>
        {children}
    </Hero>
}

export default HeroMapper