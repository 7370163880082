import {FC, useContext} from "react";

import SectionHeadings, {SectionHeadingsProps} from '../molecules/SectionHeadings'
import {Context} from "@/components/Section";

const SectionHeadingsMapper: FC<Omit<SectionHeadingsProps, 'background'>> = ({children, ...rest}) => {
    const background = useContext(Context).background as BackgroundValues

    return <SectionHeadings {...rest} background={background} >
        {children}
    </SectionHeadings>
}

export default SectionHeadingsMapper