'use client'

import {FC} from "react";
import Card from "@/components/molecules/Card";

export interface CarouselProps extends Carousel {
}

const Carousel: FC<Carousel> = ({contentType, images, cards, background}) => {
  const backgroundMapped = background === 'white' ? 'light' : background

  const carouselClasses = {
    'images': 'w-[170px] sm:w-[340px] md:w-[680px] lg:w-[1020px]',
    'image cards': 'w-[324px] sm:w-[324px] md:w-[648px] lg:w-[972px]',
  }[contentType]

  const slideshowClasses = {
    'images': 'images',
    'image cards': 'image-cards',
  }[contentType]

  const arrowClasses = ((background, contentType): string => {
    switch (background) {
      case "light":
        return {
          'images': 'fill-neutral-900 lg:hidden',
          'image cards': 'fill-teal-700'
        }[contentType]
      case "dark":
        return {
          'images': 'fill-neutral-50 lg:hidden',
          'image cards': 'fill-teal-300'
        }[contentType]
    }

    return ''
  })(backgroundMapped, contentType)

  const arrowLeft = <svg className={`arrow-left absolute cursor-pointer left-[-40px] top-[calc(50%-22px)] ${arrowClasses}`}
                         xmlns="http://www.w3.org/2000/svg" width="44"
                         height="45" viewBox="0 0 44 45" fill="none">
    <path d="M28.792 13.71L26.207 11.125L15.207 22.125L26.207 33.125L28.792 30.54L20.3954 22.125L28.792 13.71Z"/>
  </svg>

  const arrowRight = <svg className={`arrow-right absolute cursor-pointer right-[-40px] top-[calc(50%-22px)] ${arrowClasses}`}
                          xmlns="http://www.w3.org/2000/svg" width="44" height="45"
                          viewBox="0 0 44 45" fill="none">
    <path d="M17.792 11.125L15.207 13.71L23.6037 22.125L15.207 30.54L17.792 33.125L28.792 22.125L17.792 11.125Z"/>
  </svg>

  return (
    <div className={`carousel relative mx-auto ${carouselClasses}`}>
      {arrowLeft}
      <div className={`overflow-hidden ${carouselClasses}`}>
        <div className={`carousel-container flex ${slideshowClasses}`}>
          {contentType === 'images' && images.map((image, i) => (
            <div key={i} className={'element flex w-[170px]'}>
              <Element url={image.url}>
                <img src={image.imageSrc} alt={image.alt} className={'max-w-[170px] h-[100px]'}/>
              </Element>
            </div>
          ))}
          {contentType === 'image cards' && cards.map((card, i) => (
            <div key={i} className={'element flex w-[324px]'}>
              <Element url={card.url}>
                <Card imageSrc={card.imageSrc} altText={card.alt} description={card.content} background={backgroundMapped}/>
              </Element>
            </div>
          ))}
        </div>
      </div>
      {arrowRight}
    </div>
  );
}

interface ElementProps {
  children: any
  url?: string | undefined
}

const Element: FC<ElementProps> = ({children, url}) => {
  if (url) {
    return <a href={url} className={'flex grow box-border p-[12px] w-[324px]'}>{children}</a>
  }
  return <div className={'flex grow box-border p-[12px] w-[324px]'}>{children}</div>
}

export default Carousel;