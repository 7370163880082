import * as React from "react";
import {FC, ReactNode} from "react";
import H1Headline from "@/components/atoms/H1Headline";
import Subtitle2 from "@/components/atoms/Subtitle2";
import Subtitle1 from "@/components/atoms/Subtitle1";
import Eyebrow from "@/components/atoms/Eyebrow";

export type HeroProps = {
  eyebrow: string
  title: string
  description?: string
  imageSrc?: string
  icons?: { imageSrc: string }[]
  imageAlt: string
  children: ReactNode
  background: BackgroundValues
  variant: HeroVariantValues
};

const Hero: FC<HeroProps> = ({
                               variant,
                               eyebrow,
                               title,
                               description,
                               imageSrc,
                               icons,
                               imageAlt,
                               children,
                               background,
                             }) => {
  const backgroundMapper = background === 'white' ? 'light' : background

  const componentClasses = {
    'With Icons': 'text-center justify-center',
    'With Image': 'text-left',
  }[variant]

  const childrenClasses = {
    'With Icons': 'self-center',
    'With Image': 'self-start',
  }[variant]

  const eyebrowClasses = {
    'light': 'text-navy-600',
    'dark': 'text-white',
  }[backgroundMapper]

  const headingClasses = {
    'light': 'text-neutral-900',
    'dark': 'text-neutral-50',
  }[backgroundMapper]

  const subtitleClasses = {
    'light': 'text-neutral-700',
    'dark': 'text-neutral-200',
  }[backgroundMapper]

  const iconClasses = (index: number) => {
    switch (index) {
      case 0: return 'w-[40px] h-[40px] left-[20px] -top-[40px] p-[6px] ' +
        'sm:left-auto sm:right-[30px] ' +
        'md:-right-[30px] ' +
        'lg:w-[62px] lg:h-[62px] lg:p-[10px] lg:left-[0px] lg:right-auto ' +
        'xl:-left-[30px] xl:-top-[20px] ' +
        '2xl:-left-[80px] xl:-top-[20px]'
      case 1: return 'hidden w-[40px] h-[40px] p-[8px] ' +
        'sm:block sm:left-[30px] sm:-bottom-[20px] ' +
        'md:left-auto md:right-[30px] md:bottom-auto md:-top-[20px] ' +
        'lg:w-[56px] lg:h-[56px] lg:-top-[40px] lg:left-[75px] lg:right-auto ' +
        'xl:w-[72px] xl:h-[72px] xl:p-[12px] xl:-left-[70px] xl:top-[130px] ' +
        '2xl:w-[56px] 2xl:h-[56px] 2xl:-left-[10px] 2xl:top-auto 2xl:bottom-[120px]'
      case 2: return 'hidden w-[48px] h-[48px] p-[8px] ' +
        'md:block md:left-[10px] md:bottom-[40px] ' +
        'lg:w-[64px] lg:h-[64px] lg:p-[10px] lg:left-auto lg:right-[0px] lg:-bottom-[20px] ' +
        'xl:w-[56px] xl:h-[56px] xl:left-[50px] xl:top-auto xl:bottom-[10px] ' +
        '2xl:w-[72px] 2xl:h-[72px] 2xl:-left-[90px] 2xl:top-auto 2xl:bottom-[20px]'
      case 3: return 'hidden w-[32px] h-[32px] p-[6px] ' +
        'xl:block xl:-right-[40px] 2xl:-right-[60px] xl:top-[20px] ' +
        '2xl:w-[72px] 2xl:h-[72px] 2xl:-top-[20px]'
      case 4: return 'hidden w-[64px] h-[64px] p-[10px] ' +
        'xl:block xl:right-[30px] xl:bottom-[40px] ' +
        '2xl:w-[64px] 2xl:h-[64px] 2xl:bottom-[90px]'
      case 5: return 'hidden w-[56px] h-[56px] p-[10px] ' +
        'xl:block xl:-right-[40px] xl:bottom-[0px] ' +
        '2xl:w-[56px] 2xl:h-[56px] 2xl:bottom-[30px]'
    }

    return ''
  }

  return (
    <div className={`flex relative ${componentClasses}`}>
      <div className={'flex flex-col justify-center'}>
        <header className={'max-w-[1000px] m-auto'}>
          {eyebrow && <Eyebrow text={eyebrow} className={`md:text-nowrap max-md:max-w-full ${eyebrowClasses}`} />}

          <H1Headline
            text={title}
            background={background}
            className={`mt-6 max-md:max-w-full max-md:text-4xl max-md:leading-10 ${headingClasses} ${variant === 'With Icons' && ' text-[52px] tracking-[-1.3px]'}`}
          />

          {description && variant === 'With Image' && <Subtitle1 text={description} className={`mt-6 max-md:max-w-full ${subtitleClasses}`} />}
          {description && variant === 'With Icons' && <Subtitle2 text={description} className={`mt-6 max-md:max-w-full ${subtitleClasses}`} />}
        </header>
        <div className={`flex gap-4 mt-12 text-lg tracking-normal leading-7 max-md:mt-10 ${childrenClasses}`}>
          {children}
        </div>
      </div>
      {variant === 'With Image' && <div className="hidden lg:flex flex-col min-w-[40%]">
        <img
          loading="lazy"
          src={imageSrc}
          alt={imageAlt}
          className="self-stretch my-auto w-full max-md:mt-10 max-md:max-w-full"
        />
      </div>}
      {variant === 'With Icons' && <>
        {icons && icons.length && icons.map((icon, index) => <img
          loading="lazy"
          key={index}
          src={icon.imageSrc}
          className={`absolute border border-navy-300 rounded ${iconClasses(index)}`}
        />)}
      </>}
    </div>
  );
};

export default Hero;