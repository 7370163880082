import {useContext} from "react";
import {Context} from "@/components/Section";
import Button from "@/components/atoms/Button";

interface ButtonMapperProps extends Button {
  builderBlock: {
    properties?: {
      href?: string
      target?: string
    }
  }
}

const ButtonMapper = ({label, variant, size, leadingIcon, trailingIcon, builderBlock}: ButtonMapperProps) => {
  const background = useContext(Context).background

  const href = builderBlock?.properties?.href || ''
  const target = builderBlock?.properties?.target || ''

  return <Button
    label={label}
    background={background as BackgroundValues}
    variant={variant}
    size={size}
    leadingIcon={leadingIcon}
    trailingIcon={trailingIcon}
    href={href}
    target={target}
  />
}

export default ButtonMapper;