import React from "react";
import Icon from "@/components/atoms/Icon";


export interface ButtonProps extends Button {
  background: BackgroundValues
}

const Button = ({label, variant, size, leadingIcon, trailingIcon, href, target, background}: ButtonProps) => {
  const variantClasses = ((background, variant) => {
    if (variant === 'CTA') {
      return 'bg-red-600 border-red-600 text-neutral-50 hover:bg-red-800 hover:border-red-800'
    }

    if (variant === 'CTA Green') {
      return 'bg-teal-700 border-teal-700 text-neutral-50 hover:bg-teal-800 hover:border-teal-800'
    }

    if (variant === 'Primary') {
      switch (background) {
        case "light":
        case "white":
          return 'hover:bg-navy-700 text-neutral-50 bg-navy'
        case "dark":
          return 'bg-teal-300 text-neutral-900 hover:bg-teal-100'
      }
    }

    if (variant === 'Secondary') {
      switch (background) {
        case "white":
          return 'bg-navy-50 text-navy-900 border-navy-300 hover:bg-navy-200'
        case "light":
          return 'bg-white border-navy-300 text-neutral-900 hover:bg-navy-300'
        case "dark":
          return 'bg-gray-800 text-neutral-50 border-navy-600 hover:bg-navy-700'
      }
    }

    return ''
  })(background, variant)

  const baseClasses = "flex items-center gap-2 border box-border relative shrink-0 self-center mt-auto h-auto text-center rounded appearance-none cursor-pointer shadow-sm disabled:opacity-60 disabled:pointer-events-none focus:shadow-focused";

  const sizeClasses = {
    'small': "px-3 py-1 text-sm",
    'standard': "px-4 py-2 text-base",
    'large': "px-6 py-3 text-lg"
  }[size];

  return <a href={href || ''}
               className={`${baseClasses} ${variantClasses} ${sizeClasses}`}
               rel={!target ? "noopener noreferrer" : undefined}
               target={target || ''}>
    {leadingIcon !== 'none' && <Icon value={leadingIcon} variant={variant}/>}
    {label}
    {trailingIcon !== 'none' && <Icon value={trailingIcon} variant={variant}/>}
  </a>

};

export default Button;