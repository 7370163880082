import {FC} from "react";

export interface ExpandingListItemProps {
  imageSrc: string
  title: string
  description: string
  background: 'light' | 'dark'
  isCollapsed?: boolean
}

const ExpandingListItem: FC<ExpandingListItemProps> = ({imageSrc, title, description, background, isCollapsed}) => {
  const listClasses = {
    'light': 'border-slate-300',
    'dark': 'border-navy-700',
  }[background]

  const titleClasses = {
    'light': 'text-neutral-900',
    'dark': 'text-neutral-200',
  }[background]

  const descriptionClasses = {
    'light': 'text-neutral-700',
    'dark': 'text-neutral-200',
  }[background]

  return <div
    className={`text-left flex gap-3 self-stretch px-3 py-4 border-b border-solid bg-opacity-0 max-md:flex-wrap ${listClasses}`}>
    <img loading="lazy" src={imageSrc} alt="" className="shrink-0 self-start w-8 aspect-square"/>
    <div className="flex flex-col flex-1 justify-center max-md:max-w-full">
      <h2 className={`text-lg font-semibold tracking-normal leading-7 max-md:max-w-full ${titleClasses}`}>
        {title}
      </h2>
      {!isCollapsed && <p className={`mt-1 text-sm leading-5 max-md:max-w-full ${descriptionClasses}`}>
        {description}
      </p>}
    </div>
  </div>
}

export default ExpandingListItem;