import React from "react";

export interface TileProps extends Tile {
    background: BackgroundValues
}

const Tile: React.FC<TileProps> = ({imageSrc, title, description, background}) => {
  const backgroundMapped = background === 'white' ? 'light' : background

  const tileClasses = {
    'light': 'border border-solid bg-white border-slate-200',
    'dark': 'bg-white bg-opacity-[0.04]',
  }[backgroundMapped]

  const titleClasses = {
    'light': 'text-neutral-900',
    'dark': 'text-neutral-50',
  }[backgroundMapped]

  const descriptionClasses = {
    'light': 'text-neutral-700',
    'dark': 'text-neutral-200',
  }[backgroundMapped]

  return (
    <div
      className={`flex flex-col text-left grow justify-top px-6 py-8 rounded-lg max-w-[400px] w-full m-auto ${tileClasses}`}>
      <img loading="lazy" src={imageSrc} alt="" className="w-16 aspect-square"/>
      <header className="mt-8">
        <h2 className={`text-lg font-bold leading-7 ${titleClasses}`}>{title}</h2>
      </header>
      <p className={`mt-4 text-base tracking-normal leading-6 ${descriptionClasses}`}>
        {description}
      </p>
    </div>
  );
};

export default Tile;