import React from "react";

interface H1HeadlineProps extends Typography {
  background: BackgroundValues
}

const H1Headline: React.FC<H1HeadlineProps> = ({text, background, className}) => {
  const backgroundMapper = background === 'white' ? 'light' : background

  const textClasses = {
    'light': 'text-teal-700',
    'dark': 'text-teal-300',
  }[backgroundMapper]

  const textColor = (text: string) => {
    const regex = /(.*)\~(.*)\~(.*)/g;
    return text.replace(regex, '$1<span class="' + textClasses + '">$2</span>$3')
  }

  return <h1
    className={`text-[60px] font-[800] tracking-[-1.5px] leading-[60px] ${className || ''}`}
    dangerouslySetInnerHTML={{__html: textColor(text)}}
  />
}

export default H1Headline;