import {FC, useContext} from "react";

import {Context} from "@/components/Section";
import CodeSnippet, {CodeSnippetProps} from '@/components/molecules/CodeSnippet';

const CodeSnippetMapper: FC<Omit<CodeSnippetProps, 'background'>> = (props) => {
    const background = useContext(Context).background as BackgroundValues

    return <CodeSnippet {...props} background={background} />
}

export default CodeSnippetMapper;