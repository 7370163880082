"use client";

import {builder, Builder, withChildren} from "@builder.io/react";
import Section from "./components/Section";
import Grid from "@/components/Grid";
import Link from "@/components/atoms/Link";
import Text from "@/components/atoms/Text";
import ExpandingListGroupMapping from "@/components/builder/ExpandingListGroupMapping";
import ButtonMapper from "@/components/builder/ButtonMapper";
import SectionHeadingsMapper from "@/components/builder/SectionHeadingsMapper";
import TileMapper from "@/components/builder/TileMapper";
import HeroMapper from "@/components/builder/HeroMapper";
import ListItemMapper from "@/components/builder/ListItemMapper";
import CaseStudiesMapper from "@/components/builder/CaseStudiesMapper";
import CarouselMapper from "@/components/builder/CarouselMapper";
import CodeSnippetMapper from "@/components/builder/CodeSnippetMapper";
import { SupportedLanguagesEnum } from "@/components/molecules/CodeSnippet";
import CardMapper from "@/components/builder/CardMapper";
import CardGridMapper from "@/components/builder/CardGridMapper";

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!);

const backgroundValues: BackgroundValues[] = ['white', 'light', 'dark']
const layoutValues: LayoutValues[] = ['left', 'center']
const linkVariantValues: LinkVariantValues[] = ['light ui', 'dark ui']
const iconPositions: IconPositionValues[] = ['before', 'after']
const iconValues: IconValues[] = ['none', 'arrow', 'add', 'check', 'chevron left', 'chevron right', 'close']
const targetValues: TargetValues[] = ['the same window', 'a new window']
const listItemVariantValues: ListItemVariantValues[] = ['check icon', 'brand icon']
const carouselContentTypeValues: CarouselContentTypeValues[] = ['images', 'image cards']
const buttonVariantValues: ButtonVariantValues[] = ['CTA', 'CTA Green', 'Primary', 'Secondary', 'Text']
const buttonSizeValues: ButtonSizeValues[] = ['small', 'standard', 'large']
const heroVariantValues: HeroVariantValues[] = ['With Icons', 'With Image']
const headingTypeValues: HeadingTypeValues[] = ['H1 Headline', 'H2 Headline', 'H3 Headline', 'H4 Headline']
const colorValues: ColorValues[] = ['default', 'green', 'red']

Builder.registerComponent(ButtonMapper, {
  name: "Button",
  defaultStyles: {
    marginTop: "0px",
  },
  override: true,
  inputs: [
    {
      name: "label",
      type: "string",
      required: true,
      defaultValue: "Click Me!",
    },
    {
      name: "variant",
      type: "string",
      enum: buttonVariantValues,
      defaultValue: buttonVariantValues[0],
      required: true,
    },
    {
      name: "size",
      type: "string",
      enum: buttonSizeValues,
      defaultValue: buttonSizeValues[0],
      required: true,
    },
    {
      name: "leadingIcon",
      type: "string",
      enum: iconValues,
      defaultValue: iconValues[0],
      required: false,
    },
    {
      name: "trailingIcon",
      type: "string",
      enum: iconValues,
      defaultValue: iconValues[0],
      required: false,
    },
  ],
});

Builder.registerComponent(Link, {
  name: "Link",
  defaultStyles: {
    marginTop: "0px",
  },
  inputs: [
    {
      name: "variant",
      type: "string",
      enum: linkVariantValues,
      required: true,
    },
    {
      name: "label",
      type: "string",
      required: true,
    },
    {
      name: "iconPosition",
      type: "string",
      enum: iconPositions,
      defaultValue: iconPositions[0],
      required: false,
    },
    {
      name: "icon",
      type: "string",
      enum: iconValues,
      defaultValue: iconValues[0],
      required: false,
    },
    {
      name: "url",
      type: "string",
      required: true,
    },
    {
      name: "target",
      type: "string",
      enum: targetValues,
      defaultValue: targetValues[0],
      required: true,
    },
  ],
});

Builder.registerComponent(withChildren(HeroMapper), {
  name: "Hero",
  defaultStyles: {
    marginTop: "0px",
  },
  defaultChildren: [
    {
      "@type": "@builder.io/sdk:Element",
      component: {name: "Button", options: {label: "Button label"}},
    },
  ],
  childRequirements: {
    message: "You can only put Button in a Hero",
    query: {
      "component.name": {$in: ["Button"]},
    },
  },
  inputs: [
    {
      name: "variant",
      type: "string",
      enum: heroVariantValues,
      defaultValue: heroVariantValues[0],
      required: true,
    },
    {
      name: "eyebrow",
      type: "string",
      required: false,
      defaultValue: "eyebrow",
    },
    {
      name: "title",
      type: "string",
      required: true,
      defaultValue: "Title",
    },
    {
      name: "description",
      type: "longText",
      required: false,
      defaultValue: "Description",
    },
    {
      name: "imageSrc",
      type: "file",
      showIf: (options: any) => {
        return options.get('variant') === 'With Image'
      },
      allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
      required: true,
      defaultValue:
        "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
    },
    {
      name: 'icons',
      type: 'list',
      showIf: (options: any) => {
        return options.get('variant') === 'With Icons'
      },
      onChange: (options: any) => {
        if (options.get('images').length > 6) {
          options.set('images', options.get('images').slice(0, 6))
          alert('maximum items is 6')
        }
      },
      subFields: [
        {
          name: "imageSrc",
          type: "file",
          allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
          defaultValue:
            "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
        }
      ],
      defaultValue: [
        {
          imageSrc: "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
        },
      ],
    },
    {
      name: "imageAlt",
      type: "string",
      required: true,
    },
  ],
});

Builder.registerComponent(withChildren(Section), {
  name: "Section",
  defaultStyles: {
    marginTop: "0px",
  },
  override: true,
  inputs: [
    {
      name: "background",
      type: "string",
      enum: backgroundValues,
      defaultValue: backgroundValues[0],
      required: true,
    },
    {
      name: "backgroundPattern",
      type: "boolean",
      defaultValue: false,
    },
    {
      name: "narrowMargins",
      type: "boolean",
      defaultValue: false,
    },
  ],
});

Builder.registerComponent(withChildren(SectionHeadingsMapper), {
  name: "Section Headings",
  defaultStyles: {
    marginTop: "0px",
  },
  inputs: [
    {
      name: "layout",
      type: "string",
      enum: layoutValues,
      defaultValue: layoutValues[0],
      required: true,
    },
    {
      name: "eyebrow",
      type: "string",
      defaultValue: "Eyebrow",
    },
    {
      name: "heading",
      type: "longText",
      required: true,
      defaultValue: "Heading",
    },
    {
      name: "headingType",
      type: "string",
      enum: headingTypeValues,
      defaultValue: headingTypeValues[1], // H2 Headline
      required: true,
    },
    {
      name: "headingColor",
      type: "string",
      enum: colorValues,
      defaultValue: colorValues[0],
    },
    {
      name: "subtitle",
      type: "longText",
      defaultValue: "Subtitle",
    },
  ],
});

Builder.registerComponent(TileMapper, {
  name: "Tile",
  defaultStyles: {
    marginTop: "0px",
  },
  inputs: [
    {
      name: "imageSrc",
      type: "file",
      allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
      required: true,
      defaultValue:
        "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
    },
    {
      name: "title",
      type: "string",
      required: true,
      defaultValue: "Title",
    },
    {
      name: "description",
      type: "longText",
      required: true,
      defaultValue: "Description",
    },
  ],
});

Builder.registerComponent(withChildren(Grid), {
  name: "Grid",
  defaultStyles: {
    marginTop: "0px",
  },
  inputs: [
    {
      name: "cols",
      type: "string",
      enum: ["2", "3", "4"],
      defaultValue: "2",
      required: true,
    }
  ],
});

Builder.registerComponent(withChildren(ListItemMapper), {
  name: "ListItem",
  defaultStyles: {
    marginTop: "0px",
  },
  inputs: [
    {
      name: "variant",
      type: "string",
      enum: listItemVariantValues,
      defaultValue: listItemVariantValues[0],
      required: true,
    },
    {
      name: "imageSrc",
      type: "file",
      showIf: (options: any) => {
        return options.get('variant') === 'brand icon'
      },
      allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
      defaultValue:
        "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
    },
    {
      name: "title",
      type: "string",
      defaultValue: "Title",
      required: true,
    },
    {
      name: "description",
      type: "longText",
      defaultValue: "Description",
      required: false,
    }
  ],
});

Builder.registerComponent(withChildren(CarouselMapper), {
  name: "Carousel",
  defaultStyles: {
    marginTop: "0px",
  },
  inputs: [
    {
      name: "contentType",
      type: "string",
      enum: carouselContentTypeValues,
      defaultValue: carouselContentTypeValues[0],
      required: true,
    },
    {
      name: 'images',
      type: 'list',
      showIf: (options: any) => {
        return options.get('contentType') === 'images'
      },
      onChange: (options: any) => {
        if (options.get('images').length > 6) {
          options.set('images', options.get('images').slice(0, 6))
          alert('maximum items is 6')
        }
      },
      subFields: [
        {
          name: 'alt',
          type: 'text',
          required: true,
          defaultValue: 'Alt value',
        },
        {
          name: "imageSrc",
          type: "file",
          allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
          defaultValue:
            "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
        },
        {
          name: "url",
          type: "string",
        },
      ],
      defaultValue: [
        {
          alt: 'Default image',
          imageSrc: "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
          url: ""
        },
      ],
    },
    {
      name: 'cards',
      type: 'list',
      showIf: (options: any) => {
        return options.get('contentType') === 'image cards'
      },
      subFields: [
        {
          name: "content",
          type: "longText",
          required: true,
          defaultValue: "Content",
        },
        {
          name: 'alt',
          type: 'text',
          required: true,
          defaultValue: 'Alt value',
        },
        {
          name: "imageSrc",
          type: "file",
          allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
          defaultValue:
            "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
        },
        {
          name: "url",
          type: "string",
        },
      ],
      defaultValue: [
        {
          content: 'Content',
          alt: 'Default image',
          imageSrc: "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
          url: ""
        },
      ],
    },
  ],
});

Builder.registerComponent(withChildren(CaseStudiesMapper), {
  name: "Case Studies",
  defaultStyles: {
    marginTop: "0px",
  },
  inputs: [
    {
      name: 'companies',
      type: 'list',
      onChange: (options: any) => {
        if (options.get('images').length > 3) {
          options.set('images', options.get('images').slice(0, 3))
          alert('maximum items is 3')
        }
      },
      subFields: [
        {
          name: "imageSrc",
          type: "file",
          allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
          defaultValue:
            "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
        },
        {
          name: 'title',
          type: 'text',
          required: true,
          defaultValue: 'Title',
        },
        {
          name: 'description',
          type: 'richText',
          required: true,
          defaultValue: 'Description',
        },
        {
          name: "url",
          type: "string",
          required: true,
        },
      ],
      defaultValue: [
        {
          imageSrc: "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
          title: "Title",
          description: "Description",
          url: "",
        },
      ],
    },
  ],
});

Builder.registerComponent(Text, {
  name: "Text",
  override: true,
  defaultStyles: {
    marginTop: "0px",
  },
  inputs: [
    {
      name: 'text',
      type: 'richText',
      required: true,
      defaultValue: 'Enter text here...',
    }
  ],
});

Builder.registerComponent(ExpandingListGroupMapping, {
  name: "Expanding List Group",
  defaultStyles: {
    marginTop: "0px",
  },
  inputs: [
    {
      name: 'items',
      type: 'list',
      subFields: [
        {
          name: "imageSrc",
          type: "file",
          allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
          defaultValue:
            "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
        },
        {
          name: "title",
          type: "string",
          defaultValue: "Title",
          required: true,
        },
        {
          name: "description",
          type: "longText",
          defaultValue: "Description",
          required: true,
        }
      ],
    },
  ],
});

Builder.registerComponent(CodeSnippetMapper, {
  name: "Code Snippet",
  defaultStyles: {
    marginTop: "0px",
    textAlign: "left",
  },
  inputs: [
    {
      friendlyName: 'Snippets',
      name: 'codes',
      type: 'list',
      subFields: [
        {
          friendlyName: "Snippet",
          name: "code",
          type: "longText",
          required: true,
        },
        {
          friendlyName: "Label in Tab",
          name: "label",
          type: "string",
          required: true,
          helperText: "This Value will be presented in tab. Each tab MUST be unique",
        },
        {
          friendlyName: "Language",
          name: "language",
          type: "string",
          enum: SupportedLanguagesEnum,
          required: true,
          helperText: "Language which will be used to interpret your code",
        },
        {
          friendlyName: "Start line",
          name: "start",
          type: "number",
          helperText: "Starting Line of the smaller version of the code. Setting this or End Line value will enable Show More button",
        },
        {
          friendlyName: "End line",
          name: "end",
          type: "number",
          helperText: "Ending Line of the smaller version of the code. Setting this or Start Line value will enable Show More button",
        },
        {
          friendlyName: "Starting Line Number",
          name: "startingLineNumber",
          type: "number",
          helperText: "This value will represent the starting line of your whole code by default your code will start from 1",
          defaultValue: 1,
        }
      ],
    },
    {
      name: "showLineNumbers",
      friendlyName: "Show Line Numbers",
      type: "boolean",
      required: true,
      defaultValue: true,
    },
  ],
});

Builder.registerComponent(CardMapper, {
  name: "Card",
  defaultStyles: {
    marginTop: "0px",
  },
  inputs: [
    {
      name: "imageSrc",
      type: "file",
      allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
      defaultValue:
        "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
    },
    {
      name: 'altText',
      type: 'text',
      required: true,
      defaultValue: 'Alt value',
    },
    {
      name: "description",
      type: "longText",
      defaultValue: "Description",
      required: true,
    }
  ],
});

Builder.registerComponent(CardGridMapper, {
  name: "Card Grid",
  defaultStyles: {
    marginTop: "0px",
  },
  inputs: [
    {
      friendlyName: 'Cards',
      name: 'cards',
      type: 'list',
      subFields: [
        {
          name: "title",
          type: "string",
          defaultValue: "Title",
          required: true,
        },
        {
          name: "description",
          type: "longText",
          defaultValue: "Description",
          required: true,
        },
        {
          name: "url",
          type: "string",
          required: true,
        }
      ],
    },
  ],
});

Builder.register('insertMenu', {
  name: 'Our components',
  items: [
    {name: 'Button'},
    {name: 'Link'},
    {name: 'Text'},
    {name: 'Section'},
    {name: 'Section Headings'},
    {name: 'Grid'},
    {name: 'ListItem'},
    {name: 'Tile'},
    {name: 'Card'},
    {name: 'Card Grid'},
    {name: 'Hero'},
    {name: 'Carousel'},
    {name: 'Case Studies'},
    {name: 'Expanding List Group'},
    {name: 'Code Snippet'},
  ],
})
