'use client'
import React, {useState, useEffect} from 'react';
import { CopyBlock, github } from "react-code-blocks";
import CustomDark from '../../lib/code-themes/custom-dark'

export interface CodeSnippetProps {
    codes: Array<{
        language: string,
        code: string,
        label: string,
        start?: number
        end?: number
        startingLineNumber?: number,
    }>,
    showLineNumbers: boolean,
    background: BackgroundValues
}

export const SupportedLanguagesEnum = [
    'bash',
    'c',
    'clojure',
    'cpp',
    'csharp',
    'dart',
    'elixir',
    'elm',
    'erlang',
    'fsharp',
    'graphql',
    'go',
    'groovy',
    'haskell',
    'html',
    'java',
    'javascript',
    'jsx',
    'julia',
    'kotlin',
    'lisp',
    'makefile',
    'matlab',
    'objectivec',
    'ocaml',
    'php',
    'python',
    'r',
    'ruby',
    'rust',
    'scala',
    'sql',
    'swift',
    'tsx',
    'typescript'
];

const CodeSnippet = ( { codes, showLineNumbers, background }: CodeSnippetProps) => {
    const [selectedCodeIndex, setSelectedCodeIndex] = useState<number>(0);
    const [isShownMore, setIsShownMore] = useState<boolean | null>(null);
    const [visibleCode, setVisibleCode] = useState<string>("");
    const [parsedStartingLine, setParsedStartingLine] = useState<number>(1);
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        setReady(false);

        if (!Array.isArray(codes) || !codes.length) {
            return;
        }

        const codeSnippet = codes[selectedCodeIndex];

        if (codeSnippet.start || codeSnippet.end) {
            setIsShownMore(true);
            const updatedVisibleCode = codeSnippet.code
                .split('\n')
                .slice(codeSnippet.start || 1 - 1,  codeSnippet.end !== undefined ? codeSnippet.end : codeSnippet.code.split('\n').length)
                .join('\n');
            setVisibleCode(updatedVisibleCode);
            if (codeSnippet.start) {
                setParsedStartingLine(codeSnippet.startingLineNumber || 1 + codeSnippet.start - 1);
            }
        } else {
            setIsShownMore(null);
            setVisibleCode(codeSnippet.code);
            setParsedStartingLine(codeSnippet.startingLineNumber || 1);
        }

        setReady(true);
    }, [selectedCodeIndex, codes]);

    useEffect(() => {
        if (isShownMore === null) return;

        const codeSnippet = codes[selectedCodeIndex];
        setReady(false);

        if (isShownMore) {
            const updatedVisibleCode = codeSnippet.code
                .split('\n')
                .slice(codeSnippet.start || 1 - 1,  codeSnippet.end !== undefined ? codeSnippet.end : codeSnippet.code.split('\n').length)
                .join('\n');
            setVisibleCode(updatedVisibleCode);
            if (codeSnippet.start) {
                setParsedStartingLine((codeSnippet.startingLineNumber || 1) + codeSnippet.start - 1);
            }
        } else {
            setVisibleCode(codeSnippet.code);
            setParsedStartingLine(codeSnippet.startingLineNumber || 1);
        }

        setReady(true);
    }, [isShownMore, selectedCodeIndex, codes]);

    if (!Array.isArray(codes) || !codes.length) {
        return null;
    }

    let theme = null;
    switch (background) {
        case "light":
        case "white":
            theme = github
            break;
        case "dark":
        default:
            theme = CustomDark
    }

    const copyBlockProps = {
        text: visibleCode,
        language: codes[selectedCodeIndex].language,
        showLineNumbers: showLineNumbers,
        startingLineNumber: parsedStartingLine,
        theme: theme,
    };

    const getTabClasses = (index: number) => {
        if (selectedCodeIndex === index) {
            if (background === "dark") {
                return "text-teal-300 border-teal-300"
            } else {
                return "text-teal-800 border-teal-800"
            }
        } else {
            if (background === "dark") {
                return "text-neutral-200 border-transparent hover:border-neutral-200"
            } else {
                return "text-navy-900 border-transparent hover:border-navy-900"
            }
        }
    }

    return (
        <div className={`codeSnippet ${(isShownMore) ? "showLess" : ""} flex flex-col rounded-md ${background === "dark" ? "bg-gray-900 border-white/[.20]" : "bg-white border-navy-300"} border-solid border overflow-hidden`}>
            {codes.length > 1 &&
                <nav
                    className={`flex gap-3 pt-4 pr-20 pl-6 text-sm font-medium leading-5 text-sky-900 whitespace-nowrap border-b  ${background === "dark" ? "bg-gray-900 border-white/[.20]" : "bg-white border-navy-300"} max-md:flex-wrap max-md:px-5`}>
                    {codes.map((code, index) => {
                        return (
                            <button
                                key={`code_${index}`}
                                className={`border-b-2 -mb-[1px] ${getTabClasses(index)}`}
                                onClick={() => setSelectedCodeIndex(index)}
                            >
                                <div
                                    className={`px-2 pb-4`}
                                >
                                    {code.label}
                                </div>
                            </button>
                        )
                    })}
                </nav>
            }
            {ready && (
                <>
                    <CopyBlock
                        {...copyBlockProps}
                    />
                    {isShownMore !== null &&
                        <div className={`py-1 border-t flex w-full rounded-none max-md:pr-5  max-md:max-w-full ${background === "dark" ? "bg-navy-800 border-white/[.20]" : "bg-white border-navy-300"} ${background === "dark" ? "text-neutral-200" : "text-teal-800"}`}>
                            <div>
                                {!isShownMore &&
                                    <button className={`flex items-center ${background === "dark" ? "text-neutral-200 hover:text-neutral-300" : "text-teal-700 hover:text-teal-900"} px-4 py-3 `} onClick={() => setIsShownMore(true)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="rotate-180">
                                            <path
                                                d="M6.175 6.91248L10 10.7291L13.825 6.91248L15 8.08748L10 13.0875L5 8.08748L6.175 6.91248Z"
                                                fill="currentColor" />
                                        </svg>
                                        <span className="pl-2">Show less</span>
                                    </button>
                                }
                                {isShownMore && <button className={`flex items-center text-neutral-200 px-4 py-3 ${background === "dark" ? "fill-neutral-200 text-neutral-200 hover:text-neutral-300 hover:fill-neutral-300" : "fill-teal-700 text-teal-700 hover:text-teal-900 hover:fill-teal-900"}`} onClick={() => setIsShownMore(false)}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.175 6.91248L10 10.7291L13.825 6.91248L15 8.08748L10 13.0875L5 8.08748L6.175 6.91248Z"
                                            fill="currentColor" />
                                    </svg>
                                    <span className="pl-2">Show more</span>
                                </button>}
                            </div>
                        </div>}
                </>
            )}
        </div>
    )
}

export default CodeSnippet;