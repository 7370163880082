import {FC, ReactNode} from "react";

export interface ListItemProps extends ListItem {
  children?: ReactNode
}

const ListItem: FC<ListItemProps> = ({variant, imageSrc, title, description, background, children}) => {
  const backgroundMapped = background === 'white' ? 'light' : background

  const titleClasses = {
    'light': 'text-neutral-900',
    'dark': 'text-neutral-50',
  }[backgroundMapped]

  const descriptionClasses = {
    'light': 'text-neutral-700',
    'dark': 'text-neutral-200',
  }[backgroundMapped]

  return (
    <div className="flex gap-[12px]">
      {variant === 'check icon' &&
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.79508 15.8749L4.62508 11.7049L3.20508 13.1149L8.79508 18.7049L20.7951 6.70492L19.3851 5.29492L8.79508 15.8749Z"
                fill="#57969C"/>
        </svg>}
      {variant === 'brand icon' && <img loading="lazy" src={imageSrc} className={'shrink-0 self-start w-[48px] h-[48px] aspect-square'} alt={title}/>}
      <div className="flex flex-col flex-1 text-left">
        <h2 className={`text-lg font-bold leading-7 ${titleClasses}`}>{title}</h2>
        {description && <p className={`mt-2 text-base tracking-normal leading-6 ${descriptionClasses}`}>{description}</p>}
        {children}
      </div>
    </div>
  )
}

export default ListItem;