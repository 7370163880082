export default {
    lineNumberColor: `#6272a4`,
    lineNumberBgColor: `#282a36`,
    backgroundColor: `#1E293B`,
    textColor: `#f8f8f2`,
    substringColor: `#f1fa8c`,
    keywordColor: `#ff79c6`,
    attributeColor: `#50fa7b`,
    selectorTagColor: `#8be9fd`,
    docTagColor: `#f1fa8c`,
    nameColor: `#66d9ef`,
    builtInColor: `#50fa7b`,
    literalColor: `#FF79C6`,
    bulletColor: `#8BE9FD`,
    codeColor: `#50FA7B`,
    additionColor: `#f1fa8c`,
    regexpColor: `#F1FA8C`,
    symbolColor: `#F1FA8C`,
    variableColor: `#F8F8F2`,
    templateVariableColor: `#FF79C6`,
    linkColor: `#00bcd4`,
    selectorAttributeColor: `#FF79C6`,
    selectorPseudoColor: `#FF79C6`,
    typeColor: `#8BE9FD`,
    stringColor: `#F1FA8C`,
    selectorIdColor: `#50FA7B`,
    selectorClassColor: `#50FA7B`,
    quoteColor: `#E9F284`,
    templateTagColor: `#FF79C6`,
    deletionColor: `#FF79C6`,
    titleColor: `#ff555580`,
    sectionColor: `#F8F8F2`,
    commentColor: `#6272A4`,
    metaKeywordColor: `#50FA7B`,
    metaColor: `#50FA7B`,
    functionColor: `#50FA7B`,
    numberColor: `#bd93f9`,
}
